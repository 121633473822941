import React from 'react'
import { Typography, Link as AnchorLink, Box, ListItemText } from '@mui/material'

const InternalUserHelpContent = () => {
  return (
    <Box m={1}>
      <Typography variant="subtitle1" color="primary" component="span">
        New to Greenfield?
        <ul>
          <li>
            <AnchorLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://greenfielddocsite.target.com/10_training"
            >
              <ListItemText primary="How to Videos" />
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://targetonline.sharepoint.com/sites/DataSciencesPlatformProducts/SitePages/Training.aspx"
            >
              <ListItemText primary="Greenfield Training" />
            </AnchorLink>
          </li>
        </ul>
      </Typography>
      <Typography variant="subtitle1" color="primary" component="span">
        Other Helpful Links
        <ul>
          <li>
            <AnchorLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://targetonline.sharepoint.com/sites/data/SitePages/DataSciencesPlatformProducts.aspx"
            >
              <ListItemText primary="go/data" />
            </AnchorLink>
          </li>
          <li>
            <AnchorLink target="_blank" rel="noopener noreferrer" href="http://go/gf_openlab">
              <ListItemText primary="Greenfield Open labs (every Thursday 8-9am CST)" />
            </AnchorLink>
          </li>
        </ul>
      </Typography>
    </Box>
  )
}

export default InternalUserHelpContent
